import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

// import crypto from 'crypto';

const routes = [{
        path: '/home',
        name: 'home',
        component: HomeView,
        meta: {
            title: 'Home',
        },
        beforeEnter: authMiddleware
    },
    {
        path: '/',
        name: 'login',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/LoginView.vue'),
        meta: {
            title: 'Login',
        },
        beforeEnter: authMiddleware
    },
    {
        path: '/kp/list/mahasiswa',
        name: 'list mahasiswa/i kp',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/kp/listKpView.vue'),
        meta: {
            title: 'List Mahasiswa KP',
        },
        beforeEnter: authMiddleware
    },
    {
        path: '/kp/penguji/penilaian/seminar/12050110489',
        name: 'penilaian seminar kp penguji',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/kp/penguji/InputNilaiSeminarPengujiView.vue'),
        meta: {
            title: 'Penilaian Seminar KP Penguji'
        },
        beforeEnter: authMiddleware
    },
    {
        path: '/kp/pembimbing/detail/12050110489',
        name: 'nilai kp pembimbing',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/kp/pembimbing/DetailMahasiswaView.vue'),
        meta: {
            title: 'Detail Mahasiswa KP'
        },
        beforeEnter: authMiddleware
    },
    {
        path: '/kp/pembimbing/penilaian/seminar/12050110489',
        name: 'penilaian seminar kp mahasiswa',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/kp/pembimbing/InputNilaiSeminarView.vue'),
        meta: {
            title: 'Penilaian Seminar KP Mahasiswa'
        },
        beforeEnter: authMiddleware

    },
    {
        path: '/kp/instansi/penilaian/akhir/12050110489',
        name: 'penilaian akhir kp mahasiswa',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/kp/instansi/InputNilaiInstansiView.vue'),
        meta: {
            title: 'Penilaian Akhir KP Mahasiswa'
        },
        beforeEnter: authMiddleware
    },

    {
        path: '/kp/pembimbing/penilaian/bimbingan/12050110489',
        name: 'penilaian bimbingan kp mahasiswa',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/kp/pembimbing/InputNilaiBimbinganView.vue'),
        meta: {
            title: 'Penilaian Bimbingan KP Mahasiswa'
        },
        beforeEnter: authMiddleware
    },

    /*
        ROUTE UNTUK TA
    */
    {
        path: '/ta/list/mahasiswa',
        name: 'list mahasiswa/i ta',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ta/listTaView.vue'),
        meta: {
            title: 'List Mahasiswa TA',
        },
        beforeEnter: authMiddleware
    },
    {
        path: '/ta/ketua_sidang/penilaian/sidang/12050110489',
        name: 'ketua sidang penilaian sidang ta mahasiswa',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ta/KetuaSidang/InputNilaiSidangView.vue'),
        meta: {
            title: 'Penilaian Sidang TA Mahasiswa'
        },
        beforeEnter: authMiddleware

    },
    {
        path: '/ta/pembimbing/detail/12050110489',
        name: 'detail ta mahasiswa',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ta/Pembimbing/DetailMahasiswaView.vue'),
        meta: {
            title: 'Detail Mahasiswa TA'
        },
        beforeEnter: authMiddleware
    },
    {
        path: '/ta/pembimbing/penilaian/sidang/12050110489',
        name: 'penilaian sidang ta mahasiswa',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ta/Pembimbing/InputNilaiSidangView.vue'),
        meta: {
            title: 'Penilaian Sidang TA Mahasiswa'
        },
        beforeEnter: authMiddleware
    },
    {
        path: '/ta/pembimbing/penilaian/bimbingan/12050110489',
        name: 'penilaian bimbingan ta mahasiswa',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ta/Pembimbing/InputNilaiBimbinganView.vue'),
        meta: {
            title: 'Penilaian Bimbingan TA Mahasiswa'
        },
        beforeEnter: authMiddleware
    },
    {
        path: '/ta/penguji/penilaian/sidang/12050110489',
        name: 'penilaian bimbingan ta mahasiswa',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ta/Penguji/InputNilaiSidangView.vue'),
        meta: {
            title: 'Penilaian Sidang TA Mahasiswa'
        },
        beforeEnter: authMiddleware
    },


    {
        path: '/test',
        name: 'test',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/TestView.vue'),
        meta: {
            title: 'Test Post',
        },
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

function authMiddleware(to, from, next) {
    const isAuthenticated = true; // Perform your authentication checks here

    if (isAuthenticated) {
        next();
    } else {
        next({ name: 'login' });
    }
}

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    authMiddleware(to, from, next)

})

export default router