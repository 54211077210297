 <!-- cerate templae componenen -->
<template>

    <div id="sm" class="md:hidden">
        <div>
            <p class="text-base font-medium text-left text-[#28ac3d]">Assalamualaikum</p>
            <p class="text-xl font-semibold text-left text-[#28ac3d]">Raudyah Maghfirah</p>
        </div>
        <div class="flex flex-col items-center justify-center">
            <div class=" basis-2/3 max-sm:basis-1/2">
                <div
                    class=" w-auto mt-[20px] p-6 text-center bg-[#92ee87]/60 shadow-xl border border-gray-200 rounded-lg max-sm:p-2">
                    <div class="flex flex-row">
                        <div class="basis-3/4 text-left md:ml-10">
                            <p class="w-[154px] text-md font-semibold text-center text-[#1e1d1d]">Selamat Datang di</p>
                            <h1 class="mt-[40px] ml-2 italic max-sm:my-2 ">
                                <span class="font-bold text-md text-gray-800">Sistem Penginputan Nilai
                                    KP dan
                                    TA</span>
                            </h1>
                        </div>

                        <!-- <div class=" basis-auto ">
                            <div class=" ">
                                <img src="@/assets/meja.png" alt="My Image">
                            </div>
                        </div> -->
                    </div>
                    <div class="md:hidden text-left">
                        <p class="text-sm ml-2 text-gray-00 ">
                            Tempat dimana Bapak/Ibu melakukan pengisian nilai Kerja Praktek atau Tugas Akhir
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div id="md" class="max-sm:hidden">
        <div class="flex flex-col items-center justify-center">
            <div class=" basis-2/3 max-sm:basis-1/2">
                <div
                    class=" w-auto mt-[10px] p-6 text-center bg-[#92ee87]/60 shadow-xl border border-gray-200 rounded-lg max-sm:p-2">
                    <div class="flex flex-row ">
                        <div class="basis-3/4 text-left md:ml-10 ">
                            <h3 class="md:font-semibold text-xl max-sm:text-sm">Assalamualaikum, Raudyah Maghfirah</h3>
                            <h1 class="font-bold text-3xl max-sm:text-sm">
                                Selamat Datang di
                            </h1>
                            <h1 class="mt-[20px] italic max-sm:my-2">
                                <span class="font-bold text-3xl max-sm:text-md text-gray-600">Sistem Penginputan Nilai KP
                                    dan TA</span>
                            </h1>
                            <p class="max-sm:hidden md:mt-[20px] text-xl text-gray-500 ">
                                Tempat dimana Bapak/Ibu melakukan pengisian nilai Kerja Praktek atau Tugas Akhir
                            </p>
                        
                        </div>
        
                        <div class=" basis-auto">
                            <div class=" ">
                                <img width="600" height="600" src="@/assets/meja1.png" alt="My Image">
                            </div>
        
                        </div>
                    </div>
        
                </div>
        
            </div>
        </div>
    </div>
    
    <div class="my-[10px] p-4 ">
        <p class="text-xl font-semibold text-left text-[#756d6d]  max-sm:text-lg">
            Kolom Penilaian Saya
        </p>
    </div>
    <div class="flex flex-row max-sm:flex-col mb-4">
        <div class="basis-1/2">
           <a href="#/kp/list/mahasiswa"
               class="block shadow-xl max-w-lg mx-auto p-6 bg-[#92EE87]/60 border border-gray-200 rounded-lg  hover:bg-[#92EE87]/90 ">
               <h5 class="mb-2 text-2xl max-sm:text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                   Input Nilai Kerja Praktik
                </h5>
               <p class="font-normal max-sm:text-sm text-gray-700 dark:text-gray-400">
                Silahkan melakukan penginputan Nilai Kerja Praktek sebagai Pembimbing, Penguji maupun Perwakilan Instansi disini</p>
           </a>

        </div>
        <div class="basis-1/2">
            <a href="#/ta/list/mahasiswa"
                class="block max-sm:mt-6 shadow-xl max-w-lg mx-auto p-6 bg-[#92EE87]/60 border border-gray-200 rounded-lg  hover:bg-[#92EE87]/90 ">
                <h5 class="mb-2 text-2xl max-sm:text-lg  font-bold tracking-tight text-gray-900 dark:text-white">
                    Input Nilai Tugas Akhir</h5>
                <p class="font-normal max-sm:text-sm text-gray-700 dark:text-gray-400">Silahkan melakukan penginputan Nilai Kerja Praktek sebagai Pembimbing, Penguji maupun Perwakilan Instansi disini</p>
            </a>
        </div>

    </div>
</template>

<script>
     export default {
         name: 'MainHome',
         components: {}
     }
 </script>
